// @flow strict
import React from "react";
import Layout from "../components/Layout";
import Blog from "../components/Blog";
import {graphql} from "gatsby";

const BlogTemplate = ({data, location, pageContext}) => {
  const {edges} = data.allMarkdownRemark;
  return (
    <Layout title="CyberVision - Blog"
            pageTitle={'BLOG'}
            location={location}
            darkBreadcrumbs
            socialImage={data.allFile.nodes[0].publicURL}
    >
      <Blog edges={edges} location={location} pageContext={pageContext}/>
    </Layout>
  );
};

export const query = graphql`
  query BlogTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
            tagSlugs
          }
          frontmatter {
            title
            date
            category
            description
            socialImage
            tags
          }
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "blog" } }) {
      nodes {
        name
        publicURL
      }
    }
  }

`;

export default BlogTemplate;
