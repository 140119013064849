import React from 'react';
import styles from './Section.module.scss';

const Section = ({ children, backgroundColor, height }) => (
  <div className={styles.section} style={{ backgroundColor, height }}>
    {children}
  </div>
);

export default Section;
