import React, {useState} from 'react';
import styles from './Blog.module.scss';
import Typography from '@material-ui/core/Typography';
import Section from '../Section';
import Feed from '../Feed';
import GatsbyLink from 'gatsby-link';
import Button from '@material-ui/core/Button';


const Blog = ({edges, pageContext}) => {
    const {postsLimit} = pageContext;
    const [postsQuantity, setPostsQuantity] = useState(postsLimit);
    const tagsMapMaker = (map, edges) => edges.reduce((acc, edge) => {
        const {node: {frontmatter, fields}} = edge;
        frontmatter.tags.forEach((tag, ind) => {
            if (!acc[tag]) {
                acc[tag] = fields.tagSlugs[ind]
            }
        })
        return acc
    }, map);
    let tagsMap = tagsMapMaker({}, edges.slice(0, postsQuantity));
    const onLoadMore = () => {
        setPostsQuantity(postsQuantity + postsLimit);
        tagsMapMaker(tagsMap, edges.slice(postsQuantity - postsLimit, postsQuantity));
        window.scroll({
            behavior: "smooth",
            top: window.scrollY + 400
        })
    }


    return (
        <Section backgroundColor="#F7F8FB" height="auto">
            <div className={styles.blog}>
                <Typography variant="h1" color="secondary">
                    Blog
                </Typography>
                <div className={styles['tag-list']}>
                    <GatsbyLink to='/blog'
                                activeClassName={styles['active']}>
                        ALL POSTS
                    </GatsbyLink>
                    {Object.keys(tagsMap).map(tag => (
                        <GatsbyLink
                            key={tag}
                            activeClassName={styles['active']}
                            to={`/blog${tagsMap[tag]}`}>
                            {tag}
                        </GatsbyLink>
                    ))}
                </div>
                <div className={styles['blog__posts']}>
                    <Feed edges={edges.slice(0, postsQuantity)}/>

                </div>
                {edges.length > postsQuantity &&
                <Button onClick={onLoadMore} color={'secondary'} variant={'contained'}>load more</Button>
                }
            </div>
        </Section>
    );
};

export default Blog;
